
import {
    defineComponent, PropType, ref, computed
} from 'vue';
import { byteLength } from 'byte-length';
import { listChooseTag } from '@/components/common/list';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import { device } from '@/data';
import { deviceContent } from './util';
import { Roles } from './all.type';

interface InitDataType {
    insAccount: string;
}
export default defineComponent({
    emits: ['close', 'success'],
    components: {
        listChooseTag,
        deviceIcon
    },
    props: {
        role: {
            type: String as PropType< Roles >,
            default: 'ins'
        },
        initData: {
            type: Object as PropType< InitDataType >,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const AutopType = ref('0');
        const Config = ref('');

        const configToLongError = computed(() => {
            if (AutopType.value === '0') {
                return byteLength(Config.value) > 2048 ? WordList.AutoPTooLong : null;
            }
            return byteLength(Config.value) > 1024 ? WordList.OnceAutoPTooLong : null;
        });

        const {
            deviceTypeOptions,
            buildOptions,
            aptOptions,
            header,
            requestData,
            selectDevices,
            updateToList,
            isShowSelectTips,
            isShowList,
            installerOptions,
            communityOptions,
            search,
            getMultipleSelect,
            checkIfSelectDevice
        } = deviceContent(props.role, props.role === 'ins' ? props.initData.insAccount : '');

        const submit = () => {
            if (configToLongError.value === null && checkIfSelectDevice()) {
                const configParams = {
                    Config: Config.value,
                    AutopType: AutopType.value,
                    DeviceIds: selectDevices.value.join(';')
                };
                console.log('config: ', configParams);
                const type = (props.role === 'disPersonal' || props.role === 'ins') ? 'single' : 'community';
                device.setDeviceConfig(type, configParams, () => {
                    emit('success');
                    updateToList.value += 1;
                });
            }
        };

        return {
            AutopType,
            Config,
            configToLongError,
            deviceTypeOptions,
            buildOptions,
            aptOptions,
            header,
            requestData,
            selectDevices,
            updateToList,
            isShowSelectTips,
            isShowList,
            installerOptions,
            communityOptions,
            search,
            getMultipleSelect,
            submit
        };
    }
});
