import {
    ref, reactive, watch, nextTick, computed
} from 'vue';
import {
    ListHeaderItem, RequestData
} from '@/components/common/list';
import { account } from '@/data';
import { GetAllInstallerCommunity } from '@/data/src/type/account.type';
import initBuildAptChoose from '@/methods/building-apt-choose';
import { ListDataType, Roles, Option } from './all.type';

const getListByRole = () => {
    const installerHeaders: ListHeaderItem[] = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }];
    const communityHeaders: ListHeaderItem[] = [{
        name: 'Build',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Apt',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }];
    const disPersonalHeaders: ListHeaderItem[] = [{
        name: 'Installer',
        label: WordList.ProperAllTextInstaller
    }, {
        name: 'Owner',
        label: WordList.TabelPersonDeviceInHtmlOwner
    }, {
        name: 'FamilySip',
        label: WordList.FamilySip
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }];
    const disProjectHeaders: ListHeaderItem[] = [{
        name: 'Installer',
        label: WordList.ProperAllTextInstaller
    }, {
        name: 'Community',
        label: WordList.ProperAllCommunity
    }, {
        name: 'Build',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Apt',
        label: WordList.RDeviceSearchLabelRoomName
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }];
    const installerDeviceType = [{
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeStair,
        value: '0'
    }, {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeDoor,
        value: '1'
    }, {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor,
        value: '2'
    }, {
        label: WordList.ProperAllTextAuditCategoryAccess,
        value: '50'
    }];
    const communityDeviceType = [{
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeStair,
        value: '0'
    }, {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeDoor,
        value: '1'
    }, {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor,
        value: '2'
    }, {
        label: WordList.SelectManagementPhone,
        value: '3'
    }, {
        label: WordList.ProperAllTextAuditCategoryAccess,
        value: '50'
    }];
    const listData: ListDataType = {
        ins: {
            header: installerHeaders,
            deviceTypeOptions: installerDeviceType,
            url: 'v3/web/single/device/getDeviceInfoList'
        },
        com: {
            header: communityHeaders,
            deviceTypeOptions: communityDeviceType,
            url: 'v3/web/community/device/getDeviceInfoList'
        },
        disPersonal: {
            header: disPersonalHeaders,
            deviceTypeOptions: installerDeviceType,
            url: 'v3/web/single/device/getDeviceInfoList'
        },
        disProject: {
            header: disProjectHeaders,
            deviceTypeOptions: communityDeviceType,
            url: 'v3/web/community/device/getDeviceInfoList'
        }
    };
    return listData;
};

const installerOptions = ref<GetAllInstallerCommunity>([]);
const communityOptions = ref<{[key in string]: Array<{
    ID: string;
    Name: string;
}>}>({});
const getInsOrCommunityOptions = (role: 'disProject' | 'disPersonal') => {
    // 获取该dis下所有installer账户
    account.getAllInstallerCommunity((res: GetAllInstallerCommunity) => {
        installerOptions.value = res;
        if (role === 'disProject') {
            communityOptions.value = {};
            res.forEach((item) => {
                communityOptions.value[item.ID] = item.Community;
            });
        }
    });
};

const deviceContent = (role: Roles, insAccount: string) => {
    // 公共筛选项
    const {
        header,
        deviceTypeOptions,
        url
    } = getListByRole()[role];

    const requestData: RequestData = reactive({
        url,
        param: {
            Type: 'all',
            MAC: '',
            Build: '',
            Apt: '',
            Owner: '',
            FamilySip: role === 'ins' ? insAccount : '',
            Installer: '',
            Community: 'all'
        }
    });

    // 社区building、apt筛选
    const buildOptions = ref<Option[]>([]);
    const aptOptions = ref<Option[]>([]);
    if (role === 'com') {
        requestData.param.Build = 'all';
        requestData.param.Apt = 'all';
        const { buildOptions: build, aptOptions: apt, setRoomOption } = initBuildAptChoose('all', 'aptAndName');
        watch([build, apt], () => {
            buildOptions.value = build.value;
            aptOptions.value = apt.value;
        });

        // 监听社区选择楼栋，动态改变apt
        watch(() => requestData.param.Build, (buildId) => {
            requestData.param.Apt = 'all';
            setRoomOption(String(buildId));
        });
    }
    // dis社区控制筛选项显示
    if (role === 'disProject') {
        watch(() => requestData.param.Community, (val) => {
            if (val !== 'all') {
                requestData.param.Build = 'all';
                requestData.param.Apt = 'all';
                const { buildOptions: build, aptOptions: apt, setRoomOption } = initBuildAptChoose('all', 'aptAndName', val.toString());
                watch([build, apt], () => {
                    buildOptions.value = build.value;
                    aptOptions.value = apt.value;
                });
                // 监听社区选择楼栋，动态改变apt
                watch(() => requestData.param.Build, (buildId) => {
                    requestData.param.Apt = 'all';
                    setRoomOption(String(buildId));
                });
            }
        });
    }
    // 控制list显示
    const isShowList = ref(true);
    if (role === 'disPersonal' || role === 'disProject') {
        getInsOrCommunityOptions(role);
        isShowList.value = false;
    }

    const updateToList = ref(0);
    const search = () => {
        if (role === 'disPersonal') {
            isShowList.value = true;
        } else if (role === 'disProject') {
            console.log('---', requestData.param);
            if (requestData.param.MAC !== '' || requestData.param.Installer !== '') {
                isShowList.value = true;
            }
        }
        updateToList.value += 1;
    };

    const selectDevices = ref< string[] >([]);
    const isShowSelectTips = ref(false);
    const getMultipleSelect = (checkKeys: Array<string>) => {
        selectDevices.value = checkKeys;
    };
    // 检查设备是否勾选，未勾选返回false
    const checkIfSelectDevice = () => {
        if (selectDevices.value.length === 0) {
            isShowSelectTips.value = true;
            return false;
        }
        isShowSelectTips.value = false;
        return true;
    };

    return {
        deviceTypeOptions,
        header,
        requestData,
        selectDevices,
        updateToList,
        isShowSelectTips,
        buildOptions,
        aptOptions,
        isShowList,
        installerOptions,
        communityOptions,
        search,
        checkIfSelectDevice,
        getMultipleSelect
    };
};

export default null;
export {
    deviceContent,
    Roles
};